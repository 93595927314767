import React from "react";
import classes from "./Map.module.css";
import { AspectRatio, Stack, Spinner, HStack, Text } from "@chakra-ui/react";
import FixbotMap from "./index";
import { useJsApiLoader } from "@react-google-maps/api";
import { useSocketContext } from "../../lib/context/global/SocketContext";

const Map = React.memo(() => {
  const libraries = React.useMemo(() => ["places"], []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD5V-TBDxs0hFghJoxEwpSEm24peGCzM5s",
    libraries,
  });
  const { location, setLocation, setFirstLocation } = useSocketContext();

  return (
    <>
      <HStack
        flexDirection={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        spacing={{ base: "0", md: "2" }}
      >
        <Text>Current Location: </Text>
        <Text color="fixbot" fontSize="sm" p={0} m={0}>
          {location}
        </Text>
      </HStack>
      <AspectRatio minH="90vh" ratio={16 / 9} className={classes.map__inner}>
        {!isLoaded ? (
          <Stack
            pos="absolute"
            left={0}
            top={0}
            w="full"
            h="full"
            bg="rgba(0,0,0,0.2)"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Stack>
        ) : (
          <FixbotMap
            setLocation={setLocation}
            setFirstLocation={setFirstLocation}
          />
        )}
      </AspectRatio>
    </>
  );
});

export default Map;
