import React from "react";
import { DashboardWrapper } from "../../../containers";
import { FadeInPage, VehicleCard } from "../../../components";
import {
  Spinner,
  Stack,
  Text,
  Heading,
  Grid,
  Box,
  Image,
} from "@chakra-ui/react";
import useGetCanbusData from "../../hooks/socket/useGetCanbusData";
import DrivingRange from "../../../assets/svg/DrivingRange.svg";
import EngineData from "../../../assets/svg/EngineData.svg";
import FuelPump from "../../../assets/svg/fuel-pump.svg";
import Temperature from "../../../assets/svg/temperature.svg";

// const VehicleStatsContainer = ({ header, statsLen = 6 }) => (
//   <Stack spacing={4}>
//     <Box bg="#fff" p={3} py={1} w="max-content">
//       <Text fontSize="sm">{header}</Text>
//     </Box>
//     <Grid
//       templateColumns={{
//         base: "repeat(auto-fill, minmax(200px, 1fr))",
//         md: "repeat(auto-fill, minmax(190px, 1fr))",
//       }}
//       gap={2}
//       maxW="100%"
//     >
//       {Array.from(Array(statsLen)).map((stat, i) => (
//         <VehicleCard key={i} title="Average fuel Consumption" />
//       ))}
//       <VehicleCard isValue title="Running Speed (Km/hr)" />
//     </Grid>
//   </Stack>
// );
function VehicleStats() {
  const canbus = useGetCanbusData();
  const [isLoading, setLoading] = React.useState(true);
  console.log({ canbus })

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <FadeInPage>
      <DashboardWrapper
        headerComp={
          <Stack spacing={2}>
            <Heading fontSize="2rem">Vehicle Stats</Heading>
            <Text>Monitor your driving & car management stats.</Text>
          </Stack>
        }
      >
        {isLoading && !canbus && (
          <Stack w="full" h="100px" alignItems="center" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Stack>
        )}
        {!isLoading && !canbus && (
          <Stack w="full" bg="#fff" p={4} py={10} textAlign="center">
            <Text fontSize="1.2rem" color="fixbot">
              No data from dongle <strong>OR</strong> No dongle connected to
              this account
            </Text>
          </Stack>
        )}
        {/* Load stuffs if canbus data is found */}
        {canbus && (
          <>
            <Stack spacing={14}>
              <Stack spacing={4}>
                <Box bg="#fff" d="flex" p={3} py={1} w="max-content">
                  <Image src={FuelPump} boxSize="20px" alt="fuel pump emoji" />
                  <Text fontSize="sm" ml="1rem" fontWeight="700">
                    Fuel Consumption
                  </Text>
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(auto-fill, minmax(200px, 1fr))",
                    md: "repeat(auto-fill, minmax(210px, 1fr))",
                  }}
                  gap={2}
                  maxW="100%"
                >
                  {canbus.averageFuelConsumptionRate && (
                    <>
                      <VehicleCard
                        percent={canbus.averageFuelConsumptionRate}
                        title="Average fuel Consumption (L/100km)"
                      />
                    </>
                  )}
                  {canbus.fuelConsumptionVolume && (
                    <>
                      <VehicleCard
                        percent={canbus.fuelConsumptionVolume}
                        title="Single Volume Consupmption"
                      />
                    </>
                  )}
                  {canbus.instantenousFuelConsumptionRate && (
                    <>
                      <VehicleCard
                        percent={canbus.instantenousFuelConsumptionRate}
                        title="Instantenous Fuel Consumption Rate (L/100km)"
                      />
                    </>
                  )}
                  {canbus.totalFuelConsumptionVolume && (
                    <>
                      <VehicleCard
                        percent={canbus.totalFuelConsumptionVolume}
                        title="Total fuel consumption (L)"
                      />
                    </>
                  )}
                  {/* <VehicleCard isValue title="Running Speed (Km/hr)" /> */}
                </Grid>
              </Stack>
              <Stack spacing={4}>
                <Box bg="#fff" d="flex" p={3} py={1} w="max-content">
                  <Image
                    src={Temperature}
                    boxSize="20px"
                    alt="fuel pump emoji"
                  />
                  <Text fontSize="sm" ml="1rem" fontWeight="700">
                    Temperatue Readings
                  </Text>
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(auto-fill, minmax(200px, 1fr))",
                    md: "repeat(auto-fill, minmax(190px, 1fr))",
                  }}
                  gap={2}
                  maxW="100%"
                >
                  {canbus.coolantTemperature && (
                    <>
                      <VehicleCard
                        percent={canbus.coolantTemperature}
                        title="Coolant
                        Temperature (°C)"
                      />
                    </>
                  )}
                  {canbus.fuelConsumptionVolume && (
                    <>
                      <VehicleCard
                        percent={canbus.fuelConsumptionVolume}
                        title="Fuel Consumption Volume (L)"
                      />
                    </>
                  )}
                  {canbus.batteryVoltage && (
                    <>
                      <VehicleCard
                        percent={canbus.batteryVoltage}
                        title="Battery
                        Temperature (V)"
                      />
                    </>
                  )}
                </Grid>
              </Stack>
              <Stack spacing={4}>
                <Box bg="#fff" d="flex" p={3} py={1} w="max-content">
                  <Image
                    src={DrivingRange}
                    boxSize="20px"
                    alt="fuel pump emoji"
                  />
                  <Text fontSize="sm" ml="1rem" fontWeight="700">
                    Driving Speed & Range
                  </Text>
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(auto-fill, minmax(200px, 1fr))",
                    md: "repeat(auto-fill, minmax(190px, 1fr))",
                  }}
                  gap={2}
                  maxW="100%"
                >
                  {canbus.vehicleSpeed && (
                    <>
                      <VehicleCard
                        isValue
                        percent={canbus.vehicleSpeed}
                        title="Running
                        Speed (Km/hr)"
                      />
                    </>
                  )}
                  {canbus.drivingRange && (
                    <>
                      <VehicleCard
                        isValue
                        percent={canbus.drivingRange}
                        title="Driving
                        Range (Km)"
                      />
                    </>
                  )}
                  {canbus.totalMileage && (
                    <>
                      <VehicleCard
                        isValue
                        percent={canbus.totalMileage}
                        title="Total
                        Mileage (Km)"
                      />
                    </>
                  )}
                </Grid>
              </Stack>
              <Stack spacing={4}>
                <Box bg="#fff" d="flex" p={3} py={1} w="max-content">
                  <Image
                    src={EngineData}
                    boxSize="20px"
                    alt="fuel pump emoji"
                  />
                  <Text fontSize="sm" ml="1rem" fontWeight="700">
                    Engine Data
                  </Text>
                </Box>
                <Grid
                  templateColumns={{
                    base: "repeat(auto-fill, minmax(200px, 1fr))",
                    md: "repeat(auto-fill, minmax(190px, 1fr))",
                  }}
                  gap={2}
                  maxW="100%"
                >
                  {canbus.throttleOpeningWidth && (
                    <>
                      <VehicleCard
                        isValue
                        percent={canbus.throttleOpeningWidth}
                        title="Throttle
                        Open width
                        (Cm)"
                      />
                    </>
                  )}
                  {canbus.engineLoad && (
                    <>
                      <VehicleCard
                        isValue
                        percent={canbus.engineLoad}
                        title="Engine
                        Load (Kw)"
                      />
                    </>
                  )}
                  {canbus.currentNumberOfErrorCodes && (
                    <>
                      <VehicleCard
                        percent={canbus.currentNumberOfErrorCodes}
                        title="Current
                        Error Code"
                      />
                    </>
                  )}
                </Grid>
              </Stack>
            </Stack>
          </>
        )}
      </DashboardWrapper>
    </FadeInPage>
  );
}

export default VehicleStats;
