import React, { useCallback, useEffect } from "react";
import { io } from "socket.io-client";
import ENDPOINTS, { USER } from "../../../constants";
import { getUserData } from "../../../utils/utils";
import useLocalStore from "../../../modules/hooks/socket/useLocalStore";
import useGetDevices from "../../../modules/hooks/devices/useGetDevices";
import { useToast } from "@chakra-ui/react";

const SocketContext = React.createContext();

export const useSocketContext = () => React.useContext(SocketContext);

function SocketContextProvider({ children }) {
  const toast = useToast();
  const { setItem, getItem } = useLocalStore();
  const getDevice = useGetDevices();
  const [location, setLocation] = React.useState("Not found yet");
  const [firstLocation, setFirstLocation] = React.useState("Not found yet");

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_STAGING_API_URL
      : process.env.REACT_APP_PRODUCTION_API_URL;
  // const [socket, setSocket] = React.useState(null);
  const socketURL = baseUrl + ENDPOINTS.API_ADD_DEVICES;
  const userData = getUserData();

  const socketRef = React.useRef(
    io(socketURL, {
      extraHeaders: {
        authorization: `Bearer ${userData.accessToken}`,
      },
    })
  );

  const setDongle = () => {
    if (!getItem(USER.FIXBOT_USER_DEVICES)) {
      setItem(USER.FIXBOT_USER_DEVICES, getDevice.value);
    }
  };

  const handleToast = useCallback(() => {
    if (getDevice?.value) {
      if (getDevice?.value.length !== 0) {
        setTimeout(() => {
          toast({
            title: "Device Connected.",
            description: "Dongle Connection Made",
            status: "info",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
        }, 8000);
        setDongle();
      }

      if (getDevice?.value.length === 0) {
        toast({
          title: "Not Connected.",
          description: "No Dongle for this user",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDevice.value, getDevice.isLoading, getDevice.isSuccess]);

  useEffect(() => {
    handleToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      socketRef.current &&
      getItem(USER.FIXBOT_USER_DEVICES) &&
      getItem(USER.FIXBOT_USER_DEVICES).length !== 0
    ) {
      socketRef.current.emit("monitorDevice", {
        dongleId: getItem(USER.FIXBOT_USER_DEVICES)[0]?.dongleId,
      });
      // okoloc110@gmail
      // test1234

      socketRef.current.on("message", (res) => {
        console.log(res, "from message");
      });

      socketRef.current.on("exception", (res) => {
        console.log(res, "from exception"); 
      });
    }

    return () => {
      socketRef.current.close();
      socketRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDevice.isSuccess]);

  return (
    <SocketContext.Provider
      value={{
        socket: socketRef.current,
        location,
        firstLocation,
        setFirstLocation,
        setLocation,
        device: getItem(USER.FIXBOT_USER_DEVICES),
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

export default SocketContextProvider;
